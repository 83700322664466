@import "../mixins";

.shared-stream-item {
  @include broadcasts-item;

  .shared-stream-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .stream-preview-holder,
    .stream-snapshot {
      + ion-img {
        position: absolute;
        top: 8px;
        left: 8px;
        width: 32px;
        height: auto;
      }
    }

    .stream-preview-holder {
      display: flex;
      flex-wrap: wrap;
      min-height: 72px;

      &.stream-previews- {
        &2,
        &3,
        &4 {
          ion-img {
            margin-inline-end: 1px;
            width: calc(50% - 1px);
          }
        }

        &5,
        &6,
        &7,
        &8,
        &9 {
          ion-img {
            margin-inline-end: 1px;
            width: calc(33.333% - 1px);
          }
        }

        &10,
        &11,
        &12,
        &13,
        &14,
        &15,
        &16,
        &17,
        &18,
        &19,
        &20 {
          ion-img {
            margin-inline-end: 1px;
            width: calc(20% - 1px);
          }
        }
      }
    }

    .stream-logo {
      @include broadcast-logo;
    }

    .shared-stream-name-container {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;

      .shared-stream-name {
        padding: 12px 0;
        text-align: center;
        display: flex;
        justify-content: center;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (min-width: "640px") {
  .shared-stream-item {
    .shared-stream-wrapper {
      .stream-snapshot {
        width: 100%;
        height: 157px;
        object-fit: cover;
      }
    }
  }
}
