.favorites-page {
  &::part(scroll) {
    display: flex;
    flex-direction: column;
  }

  .content {
    .shared-stream-item {
      width: 14%;
    }
  }
}
