.ion-card-modal {
  --width: 100%;
  --height: 70%;

  .ion-close-button {
    position: absolute;
    z-index: 999;
    right: 25px;
    top: 22px;
  
    .ion-transparent-button {
      background: rgba(255, 255, 255, 0.2);
      --color: white;
      border-radius: 5px;
    }
  }

  .ion-card-image {
    padding: 20px;
  }
}