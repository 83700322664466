.chat-card {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 4px 8px 4px 0;
  margin: 0;
  box-shadow: none;

  .chat-card-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    overflow: hidden;

    .messages-container {
      height: 100%;
      overflow-y: auto;
      scrollbar-color: var(--ion-color-secondary) transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--ion-color-secondary);
      }

      .chat-message {
        margin: 8px;
        background: transparent;
        box-shadow: none;

        ion-card-content {
          padding: 4px 8px;
          background-color: transparent;
          display: inline-block;
          width: 100%;

          ion-card-header {
            display: flex;
            align-items: center;
            padding: 0;
            justify-content: space-between;

            .chat-participant-name {
              font-weight: bold;
            }

            .chat-date {
              padding-inline-start: 32px;
              font-size: 11px;
            }
          }
        }
      }

      @media (max-width: 1084px) {
        max-height: 300px;
      }
    }

    .chat-item-input {
      display: flex;
      align-items: center;

      &::part(native) {
        height: 60px;
      }

      ion-input {
        --padding-start: 16px;
        --padding-end: 16px;
      }

      .chat-name-left {
        padding: 0 8px;
        display: flex;
        color: grey;
        font-style: italic;

        .left {
          margin-inline-start: 4px;
        }
      }

      ion-buttons {
        margin: 0;

        ion-button {
          ion-avatar {
            margin: 0;
            $dim: auto;
            width: $dim;
            height: $dim;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--ion-color-secondary);

            ion-icon {
              font-size: 16px;
              padding: 8px;
            }

            ion-text {
              $dim: 32px;
              width: $dim;
              height: $dim;
              line-height: $dim;
              font-size: 14px;
            }
          }

          .chat-unread-all-badge {
            position: absolute;
            top: -6px;
            right: -6px;

            animation: fadein .3s ease 0s normal forwards;

            @keyframes fadein {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }
        }
      }

      ion-icon {
        font-size: 20px;
      }
    }
  }
}

.chat-popover {
  --max-height: 300px;

  ion-list {
    ion-list-header {
      font-weight: bold;
    }

    ion-item {
      &::part(native) {
        font-size: 14px;
        min-height: unset;
      }
    }
  }

  ion-avatar {
    background: var(--ion-color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 12px;
    text-transform: uppercase;

    $dim: 26px;
    width: $dim;
    height: $dim;

    ion-text {
      font-size: 12px;
    }

    ion-icon {
      font-size: 14px;
    }
  }
}
