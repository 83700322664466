.careers-header {
    font-size: 14px;
}

.careers-grid {
    padding-top: 20px;
}

.col {
    border-bottom: 1px solid #fff;
}