@import "../../../shared/mixins";

.confirm-phonenumber-modal {
  @include modal-auto-height;
  --width: 642px;
  --background: #662c4b 0% 0% no-repeat padding-box;
  --border-radius: 8px;

  .cross-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .back-button {
    position: absolute;
    left: 24px;
    top: 24px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .modal-header {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    margin-top: 24px;
  }

  .modal-subscription {
    margin-top: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
  }

  .modal-phonenumber-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 40px;
  }

  .modal-phonenumber-code-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;

    .label-code-input {
      font-size: 14px;
      color: #ffffff;
    }

    .code-input {
      --background: #ffffff;
      --color: #000;
      --border: 1px solid #707070;
      --border-radius: 4px;

      ion-icon {
        color: #000;
      }

      width: 88px;
      height: 40px;
    }
  }

  .modal-phonenumber-number-container {
    display: flex;
    align-items: flex-end;

    .number-input {
      width: 200px;
      height: 40px;
      border-radius: 4px;
      background-color: #ffffff;
      color: #000000;
      border-color: #ffffff;
    }
  }

  .receive-otp-radio-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    .receive-otp-radio-buttons-container {
      width: max-content;
    }

    .radio-buttons {
      // margin-left: 20px;
      margin-top: 10px;

      ion-radio-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 40px;
        // align-items: center;
        // width: 200px;
        // margin: 40px 0px 0px 20px;

        .radio-button {
          display: flex;
          justify-content: center;
          gap: 6px;
          align-items: center;
          // align-items: center;
          // gap: 10px;
        }
      }
    }

    ion-label {
      font-size: 14px;
    }
  }

  .save-button {
    margin-top: 16px;
    width: fit-content;
  }

  .footer {
    display: flex;
    justify-content: center;

    ion-button {
      --background: transparent
        linear-gradient(180deg, #ae00b3 0%, #d50087 100%) 0% 0% no-repeat
        padding-box;
      --border-radius: 12px;
      opacity: 1;
    }
  }

  ion-col {
    --ion-grid-column-padding: 0px;
  }

  .g-recaptcha {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    scale: 0.8;
  }

  .opt-label-container {
    margin-top: 43px;
    margin-bottom: 20px !important;

    ion-text {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .sms-call-buttons-container {
    margin-top: 24px !important;

    column-gap: 16px !important;

    ion-button {
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
    }

    ion-text {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .verify-phone-input-container {
    display: flex;
    justify-content: center;
  }

  .verify-phone-input {
    width: 290px !important;
  }

  .verify-phone-number {
    padding: 0px !important;
  }
}
