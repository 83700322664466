.hot-now-card {
  ion-card-content {
    .swiper-slide {
      img {
        max-height: 160px;
        margin-bottom: 8px;
      }
    }
  }
}
