@import "src/shared/mixins";

.live-room {
  width: 100%;
  padding-top: 0;

  ion-list-header {
    background: black;
  }

  &:first-child {
    margin-inline-start: 0;
  }

  &:last-child {
    margin-inline-end: 0;
  }

  &::part(native) {
    flex-direction: column;
  }

  .room-meta {
    position: relative;
    min-height: 112px;
    width: 100%;
    --padding-start: 0;
    --inner-padding-end: 0;

    &:hover {
      cursor: pointer;
    }

    &::part(native) {
      flex-direction: column;
    }

    .room-logo-small {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 40px;
    }

    .room-preview {
      visibility: hidden;
      height: 0;

      &.room-loaded {
        visibility: visible;
        height: auto;
      }
    }

    .room-logo {
      width: 100%;
      height: 100%;

      &::part(image) {
        max-height: 120px;
      }

      padding: 0;
    }

    .room-logo-12all {
      &::part(image) {
        max-height: 120px;
      }
    }

    ion-text {
      padding: 12px 0;
      display: block;
      text-align: center;
    }
  }

  .participants {
    align-self: flex-start;
    width: 100%;

    .host-default-avatar {
      @include host-default-avatar();
    }

    .participants-list {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      position: relative;
      align-items: center;

      .participant {
        position: absolute;
        $distance: 20px;

        &:nth-child(1) {
          right: 0;
        }

        &:nth-child(2) {
          right: $distance;
        }

        &:nth-child(3) {
          right: 2 * $distance;
        }

        &:nth-child(4) {
          right: 3 * $distance;
        }

        &:nth-child(5) {
          right: 4 * $distance;
        }

        ion-avatar,
        .default-avatar {
          width: 32px;
          height: 32px;
        }

        .default-avatar {
          border: 2px solid var(--ion-color-step-50);
          text-transform: uppercase;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.confirm-buttons-group {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-evenly;
  gap: 12px;

  ion-button {
    --color: white;
    --padding-bottom: 12px;
    --padding-end: 16px;
    --padding-start: 16px;
    --padding-top: 12px;
    font-size: 16px;
    height: auto;
  }
}

.searchable-language-modal {
  .modal-body {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    ion-text {
      font-size: 20px;
    }
    gap: 12px;

    .modal-room-price {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
