@import "./TopBar/mixins";
@import "./SideBar/mixins";
@import "../../../components/Chat/mixins";

.living-room-main {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  &-fullscreen {
    &.player-controller {
      .living-room-side-bar-card {
        bottom: 64px;
      }
    }

    .living-room-chat-container,
    .living-room-side-features {
      .ad {
        display: none;
      }
    }

    .living-room-chat-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: calc(100% - 136px);
      z-index: 1;
      @include chat-fullscreen;
    }

    .video-room-container {
      @include living-room-top-bar-fullscreen;

      .video-room-main {
        .video-room-inner {
          video {
            width: 100%;
            height: 100%;
          }

          .channel-logo-name-container {
            position: absolute;
            top: 60px;
            left: 8px;

            .lr-channel-logo {
              width: 80px;
              height: 80px;
            }
          }

          .player-control-bar {
            bottom: 20%;
          }
        }

        @include living-room-side-bar-fullscreen;
      }
    }

    .chat-card {
      padding-inline-end: 76px;

      .chat-card-content {
        .chat-item-input {
          --background: rgba(79, 79, 79, 0.1);

          &.item-has-focus {
            --background: var(--ion-item-background, var(--ion-background-color, #fff))
          }
        }
      }
    }

    .living-room-side-bar-card {
      bottom: 0;
      background-color: rgba(122, 122, 122, 0.1);
      box-shadow: none;
    }

    .living-room-side-features {
      position: absolute;
      right: 76px;
      top: 52px;
      width: 400px;

      .stream-debug {
        display: none;
      }

      .living-room-participants-card {
        background-color: rgba(79, 79, 79, 0.5);
        box-shadow: none;
        //padding-inline-end: 0;
        max-height: 350px;
        padding: 12px;

        ion-item.living-room-participant::part(native) {
          background: transparent;
        }
      }
    }
  }

  $videoContainerWidth: 1084px;

  .living-room-chat-container,
  .living-room-side-features {
    flex: 0 0;
    flex-basis: calc((100% - #{$videoContainerWidth}) / 2);
    display: flex;
    justify-content: center;
    align-items: center;

    .ad {
      width: 100%;
    }

    &.side-content-open {
      justify-content: normal;
      align-items: normal;

      .ad {
        display: none;
      }
    }
  }

  .video-room-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: $videoContainerWidth;

    .video-room-main {
      display: flex;
      height: 100%;
      overflow: auto;

      .video-room-inner {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        overflow: auto;

        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .channel-logo-name-container {
          position: absolute;
          top: 0;
          margin: 20px;

          .lr-channel-logo {
            width: 80px;
          }

          .lr-channel-name {
            font-size: 14px;
            width: 100%;
            display: block;
            margin-top: 4px;
            text-align: center;
          }
        }
      }
    }
  }

  .living-room-side-features {
    flex-direction: column;
    overflow: auto;

    .stream-debug {
      flex: 1;
    }
  }

  @media (max-width: 1500px) {
    display: block;

    .video-room-container {
      width: 100%;
    }

    .living-room-chat-container {
      @include chat-fullscreen;
      position: absolute;
      bottom: 0;
      width: calc(100% - 60px);
      height: calc(100% - 160px);
      z-index: 1;

      .ad {
        display: none;
      }
    }

    .living-room-side-features {
      .debug-stream,
      .living-room-participants-card {
        width: 100%;
      }
    }
  }
}
