.shop-layout {
  // display: flex;
  // justify-content: center;
  // width: 100%;

  ion-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .coming-soon-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 190px;

    ion-img {
      width: 200px;
      height: 200px;
    }
  }
}