.premium-card-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  height: 30%;

  .premium-card {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    min-width: 30%;
    background: transparent linear-gradient(1deg, #502B3F 0%, #662C4B 100%) 0% 0% no-repeat padding-box;
    border-radius: 12px;
    // padding-top: 40px;
    height: 100%;
    // --height: 80%;

    ion-img {
      margin-top: 20px;
    }

    ion-card-header {
      background: transparent;
      // margin-top: 30px;
      ion-card-title {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .go-button {
      --background: transparent linear-gradient(180deg, #ae00b3 0%, #d50087 100%) 0% 0% no-repeat padding-box;
      --border-radius: 12px;
      width: 133px;
      margin-top: 20px;
    }
  }
}