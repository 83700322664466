.file-validation {
  .item::part(native) {
    text-align: center;
  }

  ion-text {
    width: 100%;
  }
}

.dropzone-container.drop-file {
  margin-bottom: 20px;

  .dropzone {
    margin-top: 8px;
    padding: 20px 0;
  }
}
