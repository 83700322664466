.room-connection-status {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  ion-card-content {
    ion-text {
      font-size: 18px;
    }

    ion-spinner {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      vertical-align: middle;
    }
  }
}
