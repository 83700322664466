.layout-content {
  &::part(background),
  &::part(scroll) {
    top: 88px;
    bottom: 116px;
  }

  &.center {
    &::part(scroll) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.xl::part(scroll) {
      @media (max-width: 1200px) {
        display: block;
      }
    }

    &.lg::part(scroll) {
      @media (max-width: 992px) {
        display: block;
      }
    }

    &.md::part(scroll) {
      @media (max-width: 768px) {
        display: block;
      }
    }

  }
}

@media (max-width: 576px) {
  .layout-content {
    &::part(background),
    &::part(scroll) {
      top: 56px;
    }
  }
}