.about-page {
  .about-card {
    margin-bottom: 12px;

    .about-content {
      p {
        margin: 12px 0;
        font-size: 16px;
      }

      .get-room {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}
