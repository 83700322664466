@import "../../shared/mixins";

.parse-logo-modal {
  @include modal-auto-height;

  .crop-image-container {
    position: relative;
    height: 340px;
    margin-bottom: 8px;
  }

  ion-item {
    margin-bottom: 12px;
    color: var(--ion-color-medium);

    ion-icon {
      padding-right: 32px;
      color: var(--ion-color-medium);
    }
  }

  .drop-logo {
    .dropzone {
      padding: 20px;
    }
  }
}
