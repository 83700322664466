.stars-status-layout {
  ion-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .status-table-balance-box {
    margin-top: 30px;
  }

  .stars-status-table-container {
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    height: 100%;

    ion-grid {
      background: #662C4B;
      border-radius: 12px;
      width: 85%;
    }

    .table-header {
      padding-bottom: 20px;
    }

    .stars-status-table {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      // display: grid;
      // background-color: red;

      // [class^="star-col-"] {
      //   width: 100%;
      // }
      // position: relative;

      ion-col {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        height: 60px;
        position: relative;

      }

      .star-col-1 {
        width: 15% !important;
      }

      .star-col-2 {
        width: 70% !important;
      }

      .star-col-3 {
        display: flex;
        justify-content: flex-end !important;
        align-items: center !important;
        width: 15% !important;
        gap: 10px;
      }

      // .star-col-4 {
      //   display: flex;
      //   justify-content: center;
      //   width: 10% !important;
      // }

      ion-img {
        width: 20px;
        height: 20px;
      }
    }

    .horizontal-row {
      background-color: #989595;
      margin-top: 0px;
      width: 95%;
      // padding-left: 40px;
      // padding-right: 40px;
    }

    .stars-table-spinner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 980px) {
    .stars-status-table-container {

      .stars-status-table {
        .star-col-1 {
          width: 30% !important;
        }

        .star-col-2 {
          width: 40% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .star-col-3 {
          width: 30% !important;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .stars-status-table-container {

      .stars-status-table {
        .star-col-1 {
          width: 33.33% !important;
        }

        .star-col-2 {
          width: 33.33% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .star-col-3 {
          width: 33.33% !important;
        }
      }
    }
  }
}