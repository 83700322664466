@import "../../../shared/mixins";

.account-update-email-modal {
  @include modal-auto-height;
  --width: 600px;
  --height: 300px;
  --background: #662C4B 0% 0% no-repeat padding-box;
  --border-radius: 8px;

  .cross-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .account-update-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    row-gap: 20px;
    margin-top: 50px;

    .input-email-update {
      width: 50%;

      ion-label {
        padding-left: 0;
        margin-bottom: 8px;
        color: #ffffff;
      }

      ion-input {
        width: 100%;
      }
    }

    .input-password-update {
      width: 50%;

      ion-label {
        padding-left: 0;
        margin-bottom: 8px;
        color: #ffffff;
      }
    }

    ion-text {
      text-align: center;
    }

    .enter-button {
      margin-top: 30px;
    }

    ion-button {
      --background: transparent linear-gradient(180deg, #AE00B3 0%, #D50087 100%) 0% 0% no-repeat padding-box;
      --border-radius: 12px;
      width: 150px;
    }
  }
}