.how-to-win-layout {
  // padding: 0 30% 0 30%;
  // width: 30%;
  display: flex;
  justify-content: center;
  width: 70%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .primary {
    color: var(--ion-color-primary);
  }

  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px 0;
    position: relative;
    border-top: 1px solid #E0007A;
  }

  ul {
    padding-left: 0;
    list-style: none;
    li {
      position: relative;
      padding-left: 4px;
      .marker {
        font-size: 24px;
        position: absolute;
        left: -20px;
        color: #E0007A !important;
      }
      color: #D4D4D4 !important;
    }
  }
  .marker {
    line-height: 46px;
    font-size: 36px;
    color: #E0007A !important;
  }

  // .divider::before,
  // .divider::after {
  //     content: "";
  //     width: 100%;
  //     flex: 1;
  //     border-top: 1px solid #E0007A;
  // }

  ion-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .horizontalRow {
    background-color: #E0007A;
    margin-top: 0px;
    width: 100%;
  }

  .invite-and-win-content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 15px;
    // text-align: center;
    margin: 40px 0 40px 0;
    word-wrap: break-word;

    ion-label {
      // letter-spacing: -0.36px;
      font-size: 19px;
    }


  }

  .referral-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    // padding-right: 30px;

    .referral-list-box {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      overflow-y: scroll;
      width: 600px;
      height: 300px;
      padding: 10px 10px 20px 10px;
      border: 2px solid #363636;
      // margin-left: 20%;
      border-radius: 16px;

      .referral-phone-input {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #C7C7C7;
        border-radius: 8px;
        width: 550px;
        margin-bottom: 10px;

        // :hover {
        //   background: #662C4B;
        // }

        .country-code-item {
          --color: white;
          opacity: 1;
        }

        [class^="referral-country-code"] {
          width: 100px;
          display: flex;
          flex-direction: row;
        }


        [class^="referral-phone-number-"] {
          display: flex;
          // justify-content: center;
          align-items: center;
          width: 150px;
          height: 55px;
          // border: 1px solid #C7C7C7;
          // border-left: none;
          // border-right: none;
          // border-radius: initial;
          --background: transparent;

          ion-input {
            height: 50px;
            border: none;

            input {
              opacity: 1;
              color: #CFCFCF;
            }
          }
        }

        [class^="status-div-"] {
          width: 180px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 55px;
          border: none;
          // border: 1px solid #C7C7C7;
          // border-left: none;
          // border-right: none;
          // border-radius: initial;
          --background: transparent;
          // padding-right: 20px;

          .status-label {
            color: #EA1E63;
          }

          .label-color {
            color: #24FFC4;
          }
        }

        [class^="remind-button-div-"] {
          width: 100px;
          display: flex;
          // justify-content: center;
          border: none;
          align-items: center;
          height: 55px;
          // border: 1px solid #C7C7C7;
          // border-left: none;
          // border-radius: 0 10px 10px 0;
          --background: transparent;
          padding-right: 20px;

          .remind-button {
            display: block;
            --background: transparent linear-gradient(180deg, #ae00b3 0%, #d50087 100%) 0% 0% no-repeat padding-box;
            --border-radius: 12px;
            width: 100px;
            height: 30px;
          }
        }

        [class^="cross-button-div-"] {
          display: flex;
          margin-right: 10px;
          align-items: center;
          height: 100%;
          ion-img {
            width: 15px;
          }
          :hover {
            cursor: pointer;
          }
        }
      }

      ::-webkit-scrollbar {
        scrollbar-color: red;

      }

    }
  }

  .referral-list-box::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 16px;
    // height: 90%;
    // margin-right: 10px;
    /* Width of the scrollbar */
  }

  .referral-list-box::-webkit-scrollbar-thumb {
    background: #662C4B;
    border-radius: 16px;
    padding: 2px;
  }

  .phone-input {
    // width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .country-code {
      width: 150px;
      border: 1px solid #C7C7C7;
      border-right: none;
      border-radius: 10px 0 0 10px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;

      ion-item {
        opacity: 1;
        // --color: white;
      }
    }

    [class^="phone-number-"] {
      width: 400px;
      height: 55px;
      border: 1px solid #C7C7C7;
      border-left: none;
      border-radius: 0 10px 10px 0;
      --background: transparent;

      ion-input {
        height: 50px;
      }
    }

  }

  .bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 325px;

    .left-button {
      --background: transparent;
      color: #EA1E63;
    }

    .right-button {
      --background: transparent linear-gradient(180deg, #ae00b3 0%, #d50087 100%) 0% 0% no-repeat padding-box;
      --border-radius: 12px;
      width: 133px;
    }

  }
}