.current-balance-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .balance-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #FFFFFF;
    width: 500px;
    height: 50px;
    // margin-top: 30px;
    border-radius: 8px;
    font-size: 17px;

    .balance-left {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: center;
      gap: 20px;

      ion-img {
        width: 30px;
        height: 30px;
      }
    }

    .balance-right {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
  }
}