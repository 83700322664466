.select-logo-component {
  --padding-start: 0;
  flex: 1;

  .default-icon-avatar,
  ion-avatar {
    margin-left: 12px;
    margin-right: 20px;
  }
}
