.ion-news-modal {
  --width: 800px;
  --height: 70%px;
}

.news-row {
  .swiper {
    height: 100%;

    .swiper-slide {
      max-height: 400px;

      // ion-router-link {
      .article {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;

        .article-content {
          text-align: start;
          padding: 24px 60px;
          height: 100%;
          position: absolute;
          top: -40px;
          left: 20px;
          width: 37%;

          img {
            height: 100%;
            width: 100%;
          }

          h1 {
            color: white;
            font-size: 20px;
            font-weight: 600;
          }

          p {
            overflow: hidden;
            color: white;
            line-height: 1.5;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
      }
      // }
    }
  }

  .ad {
    margin: auto;
  }
}

@media (min-width: 1407px) and (max-width: 1650px) {
  .news-row {
    .swiper {
      .swiper-slide {
        .article {
          display: flex;
          justify-content: center;
          .article-content {
            width: 100%;
            h1 {
              margin-top: 20px;
              width: 23%;
            }
            p {
              -webkit-line-clamp: 6;
              width: 23%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1301px) and (max-width: 1406px) {
  .news-row {
    .swiper {
      .swiper-slide {
        .article {
          display: flex;
          justify-content: center;
          .article-content {
            width: 100%;
            h1 {
              margin-top: 20px;
              width: 23%;
            }
            p {
              -webkit-line-clamp: 5;
              width: 23%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .news-row {
    .swiper {
      .swiper-slide {
        .article {
          display: flex;
          justify-content: center;
          .article-content {
            position: absolute;
            text-align: start;
            padding: 1px 50px;
            width: 400px;
            align-items: center;
            top: 0px;
            left: auto;
            max-height: 200px;
            h1 {
              margin-top: 20px;
            }
            p {
              -webkit-line-clamp: 6;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .news-row {
    .swiper {
      .swiper-slide {
        .article {
          display: flex;
          justify-content: center;
          .article-content {
            padding: 10px 50px;
            width: 90%;
            p {
              -webkit-line-clamp: 4;
            }
          }
        }
      }
    }
  }
}
