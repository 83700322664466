.billing-popup-ion-reward-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  --height: 40%;
  --border-radius: 12px;
  // padding-top: 200px;

  .modal-wrapper {
    height: 200px !important;
    background: transparent !important;
  }

  .modal-shadow {
    height: 200px !important;
    background: transparent !important;

  }

  #ion-react-wrapper {
    justify-content: center;
    align-items: center;
    background-color: #662C4B;
    border-radius: 12px;
    // height: 60% !important;

    .reward-cross {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    ion-card-header {
      // margin-top: 40px;

      ion-card-title {
        color: #fff;
        font-weight: bold;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 50px;
      // margin-top: 20px;
      // padding-top: 20px;

      .sharp-star {
        width: 30px;
        height: 30px;
        // margin-top: 20px;
      }

      .card-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ion-label {
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-size: 18px;
          color: #fff;
          margin-top: 20px;
        }
      }

      .popup-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // margin-bottom: 20px;

        ion-button {
          --background: transparent linear-gradient(180deg, #AE00B3 0%, #D50087 100%) 0 0 no-repeat padding-box !important;
          --box-shadow: none !important;
          --border-radius: 12px;
          margin-top: 70px;
          width: 133px;
          height: 40px;
          // opacity: 1;
        }

        .cancel-button {
          --background: transparent !important;
          border: 1px solid #F9F9F9;
          border-radius: 12px;
        }

      }

      .cancel-button-active {
        display: flex;
        justify-content: center !important;
        align-items: center;
        width: 100%;
      }

    }

  }

}

@media (max-width: 1200px) {
  .signup-reward-container {
    display: flex;

    .signup-reward-card {
      width: 40%;
    }
  }
}

@media (max-width: 1400px) {
  .signup-reward-container {
    display: flex;

    .signup-reward-card {
      width: 50%;
    }
  }
}

@media (max-width: 992px) {
  .signup-reward-container {

    position: absolute;
    top: 20%;
    left: 23%;

    .signup-reward-card {
      width: 50%;
    }
  }
}

@media (max-width: 680px) {
  .signup-reward-container {
    // width: 100%;
    // position: none;
    top: 15%;
    left: 0;
    right: 0;
    height: 100%;
    overflow: visible;

    .signup-reward-card {
      width: 97%;
    }
  }
}