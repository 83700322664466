@import "src/shared/mixins";

.save-stream-modal {
  @include modal-auto-height;

  .toolbar-header {
    margin-bottom: 12px;

    h1, h2 {
      margin: 8px 0;
    }

    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 14px;
      color: var(--ion-color-medium);
    }
  }

  .select-logo-component,
  .language-item,
  .genre-item,
  .country-item,
  .row-item {
    margin: 4px;
  }

  .toolbar-footer {
    margin-top: 12px;
  }
}
