@import "../../shared/mixins";

.language-item {
  align-items: center;
  flex: 1;
  @include fixed-label-full-width;

  input {
    cursor: pointer;
  }

  .caret-icon {
    @include caret-icon;
  }
}

.searchable-language-modal {
  @include searchable-modal;
}
