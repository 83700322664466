.hls-js-component {
  .input-item {
    align-items: center;
  }
}

.hls-js-modal {
  --width: 1800px;
  --height: 680px;

  main {
    display: flex;
    flex: 1;
    overflow: auto;

    .room-section {
      flex: 1;

      .room-video {
        width: 800px;
        height: 450px;
      }
    }

    .config-section {
      flex: 1;

      .config-top {
        display: flex;

        .output-container {
          video {
            width: 640px;
            height: 360px;
          }
        }

        .input-container {
          flex: 1;
          margin-inline-end: 12px;
        }
      }

      .cap-width-height {
        display: flex;

        .cap-width {
          margin-inline-end: 12px;
        }

        ion-input {
          width: 50px;
        }
      }
    }

    .select-resolution {
      margin-top: 12px;
    }

    .hls-configs-header {
      margin-top: 12px;
    }

    .selected-tunes {
      max-height: 220px;
      overflow: auto;

      ion-item {
        margin-top: 4px;
      }
    }

    .message-resolution {
      display: block;
      padding: 8px 4px;
    }
  }

  @media (max-width: 1800px) {
    --width: 100%;
  }

  @media (max-width: 1450px) {
    main {
      .room-section {
        .room-video {
          width: 640px;
          height: 380px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    main {
      flex-direction: column;

      .room-section {
        .room-video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.hls-js-config-pop {
  --width: 380px;

  .config-props-list {
    height: 300px;
    overflow: auto;
  }
}
