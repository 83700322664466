.rooms-row {
  .live-rooms-col,
  .upcoming-rooms-col {
    padding-left: 24px;
    padding-right: 24px;

    .room-swiper {
      .swiper-button-prev,
      .swiper-button-next {
        color: white;
      }
    }

    .live-room {
      margin: 0 auto;
      width: 100%;
    }
  }
}
