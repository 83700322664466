.terms-content {
  p {
    margin: 12px 0;
  }

  h1, h2, h3, h4 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }
}
