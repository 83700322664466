.living-room-side-bar {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: auto;
  padding: 4px 8px;
  z-index: 1;

  &.fullscreen {
    ion-buttons ion-button {
      --background: rgba(107, 107, 107, 0.4);
    }
  }

  ion-buttons {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    ion-button {
      &.screen-share-btn.button-has-icon-only,
      &.my-stream-share-btn.button-has-icon-only,
      &.files-share-btn.button-has-icon-only {
        ion-img {
          width: 22px;
        }
      }
    }

    .chat-button ion-badge {
      position: absolute;
      top: -8px;
      right: -4px;

      &.show-delay {
        animation: fadein .3s ease 0s normal forwards 1;

        @keyframes fadein {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
}

.side-bar-media-popover {
  ion-img {
    width: 22px;
  }
}
