.player-bar-stream {
  position: absolute;
  bottom: 25%;
  left: 0;
  width: 75%;
  height: 25%;
  display: flex;
  z-index: 100;
  animation: fadeout 1s forwards;

  &:hover {
    animation: fadein 0.5s forwards;
  }

  ion-toolbar {
    --background: rgba(80, 80, 80, 0.5);
    align-self: flex-end;
    width: 200px;
    margin-inline-start: 32px;

    .volume-button.button-has-icon-only.button-clear {
      $volume-dimension: 52px;
      width: $volume-dimension;
      height: $volume-dimension;

      ion-icon {
        font-size: 30px;
      }
    }

    ion-range {
      --bar-height: 4px;
      --knob-size: 20px;
    }
  }

}
