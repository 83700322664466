.channel-schedule-modal {
  .ion-page {
    justify-content: flex-start;
  }

  ion-searchbar {
    --background: var(--ion-card-background);
  }

  .schedule-days {
    --background: var(--ion-card-background);
    margin: 0 8px;
    width: auto;

    ion-segment-button {
      min-width: auto;
      --padding-start: 4px;
      --padding-end: 4px;

      @media (max-width: 320px) {
        ion-label {
          font-size: 12px;
        }
      }
    }
  }

  .epg-entries-card {
    overflow: auto;
    margin: 8px;

    ion-card-content {
      .epg-entities-day-container {
        .day {
          display: block;
          padding: 8px 0;
          font-weight: bold;
        }

        .program {
          margin-bottom: 8px;
          --min-height: unset;

          ion-text {
            font-size: 14px;

            .time {
              margin-inline-end: 12px;
            }
          }
        }
      }

      .loading-epg-entries {
        align-self: center;
      }
    }
  }

  .loading-epg-entries {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    ion-card {
      padding: 12px 20px;
    }
  }
}
