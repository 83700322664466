.skip-container {
  min-width: 376px;

  ion-card-title{
    padding-top: 0.35rem;
  }

  .icon-block {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    ion-icon {
      width: 4rem;
      height: 4rem;
    }
  }

  .ion-transparent-button {
    position: absolute;
    z-index: 999;
    right: 20px;
    width: 45px;
    border-radius: 5px;
    display: block;
  }

  .button-solid {
    --box-shadow: none;
  }

  ion-item {
    margin: 1rem 0;
    &::part(native) {
      border-bottom-color: #9f9f9f;
    }

    &:last-of-type {
      margin-bottom: 3rem !important;
    }

    &:first-of-type {
      margin-top: 3rem;
    }
  }

  ion-card-title {
    font-size: 1.5rem;
    text-align: center !important;
  }

  ion-button {
    margin-top: 20px;
  }

  @media (max-width: 576px) {
    min-width: auto;
  }
}
