@import "src/shared/mixins";
@import "../../components/Chat/mixins";

.shared-stream-page {
  $videoContainerWidth: 1084px;

  width: 100%;
  height: 100%;
  display: flex;

  &.fullscreen {
    .top-bar-stream-share {
      position: fixed;
      top: 0;

      .room-info {
        --background: rgba(107, 107, 107, 0.4);
      }
    }

    .shared-stream-chat-section {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 100;
      height: calc(100% - 48px);
      width: calc(100% - 68px);
      @include chat-fullscreen;
    }

    .shared-stream-room-section {
      width: 100%;

      .stream-content-holder {
        width: 100%;

        .stream-holder {
          overflow: hidden;
        }
      }

      .stream-side-bar {
        position: fixed;
        right: 0;
        bottom: 0;
        --background: rgba(79, 79, 79, 0.1);
      }
    }

    .shared-stream-room-section-tv {
      width: 100%;
      height: 100%;

      .stream-content-holder {
        width: 100%;
        height: 100%;

        .stream-holder {
          overflow: hidden;
        }
      }

      .stream-side-bar {
        position: fixed;
        right: 0;
        bottom: 0;
        --background: rgba(79, 79, 79, 0.1);
      }
    }

    .shared-stream-side-features {
      display: none;
    }

    .chat-card .chat-card-content .messages-container .chat-message {
      ion-card-content {
        background: rgba(73, 73, 73, 0.5);
      }
    }
  }

  .shared-stream-chat-section,
  .shared-stream-side-features {
    flex: 0 0;
    flex-basis: calc((100% - #{$videoContainerWidth}) / 2);
  }

  .shared-stream-side-features {
    overflow: auto;
  }

  .shared-stream-room-section {
    display: flex;
    justify-content: center;
    width: $videoContainerWidth;
    height: 100%;

    .stream-content-holder {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      @include fade-frames;

      .stream-holder {
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &.left {
          justify-content: left;
        }

        .stream-room-container {
          flex: 1;
          height: calc(100% - 12px);

          ion-img {
            // width: 100%;
            width: 75.5%;
            height: calc(100% - 32%);
          }

          .stream-room-video {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1500px) {
    display: block;

    .shared-stream-room-section {
      width: 100%;
    }

    .shared-stream-chat-section {
      @include chat-fullscreen;
      position: absolute;
      bottom: 0;
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      z-index: 1;
    }
  }
}

#video-player {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ads-container {
  position: absolute;
  width: 500px;
  height: 300px;
  top: 30%;
  left: 40%;
  z-index: 300;
  transform: translate(-40%, -30%);
}
