.wp-start-2 {
  width: var(--custom-full-width);
  margin: 0 auto;
  --background: var(--ion-background-color);
  display: flex;
  flex-direction: column;
  height: 100%;

  ion-card-header {
    ion-card-title {
      text-align: center;
    }
  }

  ion-card-content {
    overflow-y: auto;
    padding-bottom: 0;
    height: 100%;
    $scheduledRoom: 350px;
    $scheduledRoomMargin: 16px;

    &.has-scheduled-rooms {
      .scheduled-rooms-2-section {
        width: $scheduledRoom;
        margin-inline-end: $scheduledRoomMargin;
        display: inline-block;
        vertical-align: top;
        height: calc(100% - 32px);
      }

      .what-are-you-sharing-section {
        width: calc(100% - #{$scheduledRoom + $scheduledRoomMargin});
        display: inline-block;
        vertical-align: top;
      }
    }

    .scheduled-rooms-2-section {
      display: none;
    }

    .go-to-step1 {
      padding: 0 8px;

      ion-text {
        margin-left: 4px;
      }
    }

    .wp-toolbar {
      padding-inline-end: 12px;
    }

    .divider {
      height: 2px;
      background-color: var(--ion-color-primary);
      margin: 8px 0 20px;
    }

    h2 {
      padding: 0 20px;
    }

    main {
      padding: 10px 10px 0;
    }

    ion-item {
      margin-bottom: 4px;

      &.input-item {
        $placeholder-size: 14px;

        input {
          &::-webkit-input-placeholder {
            font-size: $placeholder-size;
          }

          &::-moz-placeholder {
            font-size: $placeholder-size;
          }
        }

        ion-select {
          --placeholder-color: var(--ion-color-step-500);
          --placeholder-opacity: 1;

          &::part(placeholder) {
            font-size: $placeholder-size;
          }
        }
      }
    }

    .start-now {
      ion-grid,
      ion-col {
        padding: 0;
      }
    }
  }

  .start-footer {
    padding: 8px 8px 0;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    ion-card-content {
      &.has-scheduled-rooms {
        .scheduled-rooms-2-section {
          width: 100%;
          margin-inline-end: 0;
          display: block;
          max-height: 200px;
        }

        .what-are-you-sharing-section {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.room-is-busy-alert {
  --background: var(--ion-card-background);
}
